<template>
  <div id="devices">
    <div class="page-title px-4 mb-4">
      <h2>
        <span class="me-2">Device Management</span>
      </h2>
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>

    <v-card>
      <!-- Devices Filter fields start -->
      <v-row class="pt-4 pb-0 ma-0">
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="sqDeviceID"
            label="Device ID"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqDeviceID')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="sqVehicleID"
            label="Vehicle ID"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqVehicleID')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="sqDStatus"
            :items="dStatusItems"
            item-text="text"
            item-value="value"
            label="Status"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqDStatus')"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="sqVersion"
            :items="dReleaseVersionItems"
            item-text="text"
            item-value="value"
            label="Release Version"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqVersion')"
          ></v-select>
        </v-col>
      </v-row>
      <!-- Devices Filter fields end -->

      <!-- Devices Table start -->
      <v-data-table
        :headers="deviceHeaders"
        :items="filteredDevices"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :custom-sort="customSort"
        class="font-weight-semibold text--primary"
        :page.sync="page"
        :must-sort="true"
      >
        <template v-slot:item.device_id="{ item }">
          <router-link
            :to="{ name: 'next-stop-device-config', params: { id: item.device_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary">{{ item.device_id }}</span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.formattedVehicleId="{ item }">
          <router-link
            :to="{ name: 'next-stop-device-config', params: { id: item.device_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary" v-html="formattedVehicleId(item)"></span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.convertDeviceDate="{ item }">
          <router-link
            :to="{ name: 'next-stop-device-config', params: { id: item.device_id } }"
            class="cursor-pointer text-decoration-none text-no-wrap"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary" v-html="convertDeviceDate(item)"></span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.convertPositionDate="{ item }">
          <router-link
            :to="{ name: 'next-stop-device-config', params: { id: item.device_id } }"
            class="cursor-pointer text-decoration-none text-no-wrap"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary" v-html="convertPositionDate(item)"></span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.release_version="{ item }">
          <router-link
            :to="{ name: 'next-stop-device-config', params: { id: item.device_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span :class="['font-weight-semibold', isLatestRelease(item) ? 'success--text' : 'error--text']">
                  {{ item.status.release_version }}
                </span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.type="{ item }">
          <router-link
            :to="{ name: 'next-stop-device-config', params: { id: item.device_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary">{{ item.config.ssd_serial }}</span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.case_id="{ item }">
          <router-link
            :to="{ name: 'next-stop-device-config', params: { id: item.device_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary">{{ item.config.case_id }}</span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.device_status="{ item }">
          <router-link
            :to="{ name: 'next-stop-device-config', params: { id: item.device_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span
                  :class="[
                    'font-weight-semibold',
                    deviceStatus(item.status.status) === 'online' ? 'success--text' : 'error--text',
                  ]"
                >
                  {{ deviceStatus(item.status.status) }}
                </span>
              </div>
            </div>
          </router-link>
        </template>
      </v-data-table>
      <!-- Devices Table end -->
    </v-card>
  </div>
</template>

<script>
import { mdiSquareEditOutline } from "@mdi/js"
import { mapState } from "vuex"
import useAppConfig from "@core/@app-config/useAppConfig"
import store from "@/store"
import urlHelpers from "@/mixins/urlHelpers"
import globalHelpers from "@/mixins/globalHelpers"

export default {
  name: "DeviceList",
  metaInfo: {
    title: "Devices",
  },
  mixins: [urlHelpers, globalHelpers],

  data() {
    return {
      devicesLiveUpdate: null,
      sqVehicleID: "",
      sqDeviceID: "",
      sqMStatus: "",
      sqDStatus: "",
      sqVersion: "",
      sqPriority: "",
      sqType: "",
      sortBy: "device_id",
      sortDesc: false,
      // Device Table Headers
      deviceHeaders: [
        { text: "Device ID", value: "device_id" },
        { text: "Vehicle ID", value: "formattedVehicleId" },
        { text: "Last Updated", value: "convertDeviceDate" },
        { text: "Last Position", value: "convertPositionDate" },
        { text: "Release Version", value: "release_version" },
        { text: "SSD ID", value: "config.ssd_serial" },
        { text: "Hardware ID", value: "case_id" },
        { text: "SIM ID", value: "config.sim_id" },
        { text: "Status", value: "device_status" },
      ],
      dStatusItems: [
        {
          text: "",
          value: "",
        },
        {
          text: "online",
          value: "online",
        },
        {
          text: "offline",
          value: "offline",
        },
      ],
      dReleaseVersionItems: [
        {
          text: "",
          value: "",
        },
        {
          text: "latest",
          value: "latest",
        },
        {
          text: "outdated",
          value: "outdated",
        },
      ],
      formData: {
        title: "",
        description: "",
        selectedDevice: null,
      },
      dialog: false,
      is_loading: true,
    }
  },
  setup() {
    return {
      icons: {
        mdiSquareEditOutline,
      },
    }
  },

  mounted() {
    const { refreshIntervalDevices } = useAppConfig()

    this.devicesLiveUpdate = setInterval(async () => {
      await store.dispatch("getDevices")
    }, refreshIntervalDevices * 1000)
  },

  computed: {
    ...mapState({
      devices: state => state.gtfs.devices,
    }),
    // Devices Filtering
    filteredDevices() {
      return this.devices.filter(device => {
        let include = true

        if (include && this.sqVehicleID.length) {
          include = device.config.vehicle_id?.toLowerCase().includes(this.sqVehicleID.toLowerCase())
        }

        if (include && this.sqDeviceID.length) {
          include = device.device_id?.toLowerCase().includes(this.sqDeviceID.toLowerCase())
        }

        if (include && this.sqDStatus !== "") {
          include = this.deviceStatus(device.status.status) === this.sqDStatus
        }

        if (include && this.sqVersion !== "") {
          include = this.isLatestRelease(device) ? this.sqVersion === "latest" : this.sqVersion === "outdated"
        }

        return include
      })
    },

    formattedVehicleId() {
      return function (item) {
        return item.config.vehicle_id ? item.config.vehicle_id : "Unknown"
      }
    },
    fetchVehicleId() {
      return function (item) {
        return this.getVehicleId(item.device_id)
      }
    },
    convertDeviceDate() {
      return function (item) {
        return item.status.device_timestamp ? this.formatDate(item.status.device_timestamp) : "Unknown"
      }
    },
    convertPositionDate() {
      return function (item) {
        return item.status.position_timestamp ? this.formatDate(item.status.position_timestamp) : "Unknown"
      }
    },
    convertCreatedDate() {
      return function (item) {
        return item.created ? this.formatDate(item.created) : "Unknown"
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.devicesLiveUpdate)
    clearInterval(this.liveUpdate)
  },

  methods: {
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },

    refreshList() {
      store.dispatch("getDevices")
    },
    deviceStatus(device) {
      return device === "idle" || device === "updating" ? "online" : "offline"
    },
    getVehicleId(deviceId) {
      const device = this.devices.find(d => d.device_id === deviceId)

      return device && device.config.vehicle_id ? device.config.vehicle_id : "Unknown"
    },
    customSort(items, sortBy, sortDesc) {
      const sortByField = sortBy[0] ?? ""
      const sortDescending = sortDesc[0] ?? false

      items.sort((a, b) => {
        let sortA = a[sortByField]
        let sortB = b[sortByField]

        // Special handling of field sorting when required
        switch (sortByField) {
          case "formattedVehicleId": {
            sortA = this.formattedVehicleId(a)
            sortB = this.formattedVehicleId(b)

            break
          }
          case "convertDeviceDate": {
            sortA = this.convertDeviceDate(a)
            sortB = this.convertDeviceDate(b)

            break
          }
          case "convertPositionDate": {
            sortA = this.convertPositionDate(a)
            sortB = this.convertPositionDate(b)

            break
          }
          case "config.ssd_serial": {
            sortA = a.config.ssd_serial
            sortB = b.config.ssd_serial

            break
          }
          case "config.case_id": {
            sortA = a.config.case_id
            sortB = b.config.case_id

            break
          }
          case "config.sim_id": {
            sortA = a.config.sim_id
            sortB = b.config.sim_id

            break
          }
          case "device_status": {
            sortA = this.deviceStatus(a.status.status)
            sortB = this.deviceStatus(b.status.status)

            break
          }
          case "release_version": {
            sortA = a.status.release_version
            sortB = b.status.release_version

            break
          }

          default: {
            break
          }
        }

        if (sortA > sortB) return 1
        if (sortA < sortB) return -1

        return 0
      })

      // Reverse order if header clicked again
      if (sortDescending) {
        items.reverse()
      }

      return items
    },

    isLatestRelease(item) {
      return item.status.release_version === item.status.target_release_version
    },
  },
}
</script>
